import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import FactorsAffectingPriceLoading from 'images/blog-images/factors-affecting-price-shipping-loading.png';
import TransportationsIssuesImg from 'images/blog-images/factors-affecting-price-shipping.png';
import FactorsAffectingPriceFeatured from 'images/featured-image/factors-affecting-price-featured.png';
import NewFBARoutesImg from 'images/blog-images/new-fba-routes.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query FactorsAffectingPriceImg {
      file(relativePath: { eq: "factors-affecting-price.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="factors affectiong price"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="What are the Factors Affecting the Price of International Shipping? | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-what-are-the-factors" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "What are the Factors Affecting the Price of International Shipping?",
              content: intl.formatMessage({ id: "blog-post-meta-title-what-are-the-factors" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FactorsAffectingPriceFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="what-are-the-factors-affecting-the-price-of-international-shipping"
        title="What are the Factors Affecting the Price of International Shipping?"
        date="2020-11-18"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              One of the most concerned issues of international shipping is the price of freight transportation. The cost is directly related to the transportation cost. Since many exporters do not know the transportation price very well, and they often incur a lot of extra expenses. Some of our eCommerce sellers often asked us why we need to provide them different rate cards every month. Let’s learn more about the factors of international freight transportation price today.
            </p>

            <h5>
              <strong>
                Transportation price
              </strong>
            </h5>

            <p>
              The transportation price is generally called the freight price, and generally refers to the cost incurred in the process of freight transportation. Another more important concept in transportation price is the transportation price index. Generally in the market of international shipping, the transportation price index is used to measure the level and dynamics of transportation prices.
            </p>

            <Image src={FactorsAffectingPriceLoading} className="" alt="transportation price"/>

            <h5>
              <strong>
                What are the factors affecting transportation prices?
              </strong>
            </h5>
            
            <p>
              In the process of international transportation, there are still many factors that affect the transportation price. In addition to some fuel and labor costs, there are many important factors. For example, things like the nature of the goods being transported, their own value, and the form of packaging, as well as the stowage factor and the transporter’s requirements for transportation. During the use of ships, there will be some fixed costs, such as ship maintenance insurance and fuel consumption. There is also the change in the relationship between supply and demand in the market. In the off-season, the transportation price is relatively low. In the peak season, the transportation price is relatively high.
            </p>

            <p>
              Some of the most common issues eCommerce Sellers are experiencing due to the <Link to="/covid-19/">COVID-19 pandemic</Link> are air shipping prices skyrocketing because of a reduction in the number of flights, and fulfillment centers suffering from overload and shipment delays.
            </p>

            <Image src={TransportationsIssuesImg} alt="transportation issues"/>

            <h5>
              <strong>
                Different transportation methods have different transportation prices
              </strong>
            </h5>

            <p>
              In the general process of international trade, the price of transportation varies according to different transportation methods. International transportation prices are divided into sea freight rates, air freight rates and railway freight rates.
            </p>

            <p>
              The specific freight rate can also be subdivided. For example, rail freight can also be divided into passenger transportation costs and freight transportation costs. Sea freight rates are also divided into non-scheduled shipping rates and liner shipping rates. The non-scheduled shipping rates are also affected by market supply and demand at the time, while liner shipping costs are relatively fixed. The air freight rate can be divided into general cargo, designated cargo and special cargo. The specific transportation price is also determined according to the weight of the transportation. At the same time, even if the size is small, the weight is also counted by weight, but if the opposite is the volume Large and small weight should be calculated according to the specific volume.
            </p>

            <Image src={NewFBARoutesImg} className="" alt="new fba and prep routes"/>

            <h5>
              <strong>
                Shipkoo Solution - <Link to="/services/express-shipping/">Express Shipping</Link> and FBA Prep & Forwarding
              </strong>
            </h5>

            <p>
              Besides sea freight, air freight and railway freight, we would like to Introduce our fourth methods - trucks. Shipkoo’s new route from China to Europe enables eCommerce sellers to ship products to 28 countries in Europe in 10-16 days. Simply send your shipment to our hub and we take it from there. We make sure to prepare everything according to <Link to="/fba-prep-and-forwarding/">Amazon's standards</Link> and ship it to your desired FBA fulfillment center or send them directly to your end customers. We help you to save up to 40% shipping cost compared to air shipping with the same delivery lead time. Free feel to <Link to="/contact/">contact our shipping specialists</Link> for more information regarding our new route.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;